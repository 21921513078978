import SanityBlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

import {
  ContactForm,
  CopyWithFullHeightImage,
  CopyWithImages,
  Layout,
  RescueService,
  SEO,
} from '../components';
import { CallToAction } from '../components/call-to-action';

function KoalaProblemsPage({ data }) {
  const pageData = data.sanityKoalaProblemsPage;
  return (
    <Layout
      heroImage={pageData.heroImage.asset.fluid}
      heroAltText={pageData.heroImage.altText}
    >
      <SEO title="Koala Problems" />
      <KoalaProblems data={pageData.koalaProblems} />
      <WhatCanYouDo data={pageData.whatCanYouDo} />
      <RescueService />
      <ContactForm />
    </Layout>
  );
}

KoalaProblemsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

function KoalaProblems({ data }) {
  return (
    <CopyWithImages images={data.images}>
      <SanityBlockContent blocks={data._rawText} className="prose" />
      {data.callToAction ? (
        <CallToAction callToAction={data.callToAction} />
      ) : null}
    </CopyWithImages>
  );
}

KoalaProblems.propTypes = {
  data: PropTypes.object.isRequired,
};

function WhatCanYouDo({ data }) {
  return (
    <CopyWithFullHeightImage variant="GREEN" image={data.images[0]}>
      <SanityBlockContent
        blocks={data._rawText}
        className="prose prose-on-dark"
      />
      {data.callToAction ? (
        <CallToAction callToAction={data.callToAction} />
      ) : null}
    </CopyWithFullHeightImage>
  );
}

WhatCanYouDo.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  {
    sanityKoalaProblemsPage {
      heroImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
        altText
      }
      koalaProblems {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
      whatCanYouDo {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
    }
  }
`;

export default KoalaProblemsPage;
